/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { Component, useContext } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { AppContext } from "~context/AppContext";
import { DocumentContext } from "~context/DocumentContext";
import Footer from "~components/Footer";
import GetInTouch from "~components/GetInTouch";
import Layout from "~components/Layout";
import LocaleLink from "~components/LocaleLink";
import Newsletter from "~components/Newsletter";
import NavClipped from "~components/NavClipped";
import SEO from "~components/SEO";
import SwiperCarousel from "~components/SwiperCarousel.jsx";
import Video from "~components/Video.jsx";

class ProjectPageComponent extends Component {
  state = {
    campaignsExpanded: false,
    creditsExpanded: false,
    expandedItems: [],
    nextProjectLink: undefined,
    projectTags: undefined,
    relatedCampaigns: undefined
  };

  timeouts = [];

  componentDidMount() {
    const { appContext } = this.props;

    appContext.setLanguageWidgetColor(`black`);
    appContext.setNavColor(`black`);
  }

  componentWillReceiveProps({ frontmatter, otherProjects }) {
    if (this.state.nextProjectLink && this.state.projectTags) {
      return;
    }

    if (frontmatter && otherProjects && otherProjects.length > 0) {
      this.setProjectData(frontmatter, otherProjects);
    }
  }

  componentWillUnmount() {
    const { appContext } = this.props;

    appContext.setLanguageWidgetColor(`white`);
    appContext.setNavColor(`white`);

    this.clearTimeouts();
  }

  //

  clearTimeouts = () => {
    this.timeouts.forEach(timeout => {
      clearTimeout(timeout);
    });
  };

  //

  setProjectData = (frontmatter, otherProjects) => {
    //
    // tags

    const projectTags = [];

    let tags;

    if (frontmatter?.tags?.includes(`,`)) {
      tags = frontmatter.tags.split(`,`);

      tags.forEach(tag => {
        if (!projectTags.includes(tag)) {
          projectTags.push(tag.trim());
        }
      });
    }

    //
    // next/related projects

    const relatedCampaigns = [];
    const relatedProjectTitles = [];

    let nextProjectLink;

    if (frontmatter.relatedProjects && frontmatter?.relatedProjects !== ``) {
      frontmatter.relatedProjects.split(`,`).forEach(relatedProjectTitle => {
        relatedProjectTitles.push(relatedProjectTitle.toUpperCase().trim());
      });
    }

    otherProjects.sort((a, b) => {
      const priorityA = a.frontmatter.priority;
      const priorityB = b.frontmatter.priority;

      if (priorityA < priorityB) {
        return -1;
      }

      if (priorityA > priorityB) {
        return 1;
      }

      return 0;
    });

    otherProjects.forEach(otherProject => {
      if (
        relatedProjectTitles &&
        relatedProjectTitles.length > 0 &&
        relatedProjectTitles.includes(
          otherProject.frontmatter.title.toUpperCase().trim()
        )
      ) {
        relatedCampaigns.push(otherProject);
      }

      if (nextProjectLink) {
        return;
      }

      if (otherProject?.frontmatter?.priority >= frontmatter?.priority) {
        let localeLink = otherProject.fields.slug;

        if (localeLink.includes(`.`)) {
          [localeLink] = otherProject.fields.slug.split(`.`);
        }

        nextProjectLink = localeLink;
      }
    });

    if (!nextProjectLink) {
      let localeLink = otherProjects[0].fields.slug;

      if (localeLink.includes(`.`)) {
        [localeLink] = otherProjects[0].fields.slug.split(`.`);
      }

      nextProjectLink = localeLink;
    }

    //

    this.setState({
      nextProjectLink,
      projectTags,
      relatedCampaigns
    });
  };

  splitStringToParagraphs = string => {
    const splitText = string.split(/\r?\n/);
    const jsx = [];

    splitText.forEach((row, index) => {
      const rowIndex = index;

      if (row !== ``) {
        jsx.push(
          <p key={`split-text-${rowIndex}`} className="relative block mb-4">
            {row}
          </p>
        );
      }
    });

    return jsx;
  };

  //

  render() {
    const { documentContext, frontmatter, location } = this.props;
    const {
      campaignsExpanded,
      creditsExpanded,
      nextProjectLink,
      projectTags,
      relatedCampaigns
    } = this.state;
    const { device } = documentContext;

    //

    return (
      <>
        <SEO
          customTitle={frontmatter.title}
          customDescription={frontmatter.seoDescription}
          customKeywords={frontmatter.seoKeywords}
          path={location.pathname}
        />

        <Layout className="project-page w-full relative" location={location}>
          <div className="w-full relative">
            <NavClipped color="black" />

            <section className="relative pt-16 pb-24 xs:pb-6">
              <div className="relative grid">
                <article className="grid-end-17 grid-start-4 sm:grid-end-22 sm:grid-start-2 xs:pt-4">
                  <h1 className="project-page__title animation-appear-right animation-delay-3 b1">
                    — {frontmatter.title}
                  </h1>

                  <p className="project-page__tagline animation-appear-right animation-delay-4 mt-6 f1">
                    {frontmatter.tagline}
                  </p>
                </article>

                {device === `desktop` && (
                  <ul className="animation-appear-right animation-delay-5 grid-end-2 grid-start-23 mt-16">
                    {projectTags &&
                      projectTags.map(tag => (
                        <li key={tag} className="my-2 b3">
                          {tag}
                        </li>
                      ))}
                  </ul>
                )}
              </div>
            </section>

            {(device === `desktop` && (
              <div className="project-page__content project-page__content--desktop relative">
                {frontmatter.components &&
                  frontmatter.components.map((component, index) => {
                    const key = `section-${index}`;

                    let componentJSX;

                    switch (component.type) {
                      case `mixedCarousel`:
                        if (!component?.carouselItem?.[0]) {
                          componentJSX = <></>;
                        } else {
                          componentJSX = (
                            <section key={key} className="relative mt-8 mb-8">
                              <div className="grid">
                                <div className="grid-end-21 grid-start-4 sm:grid-end-24 sm:grid-start-1 relative">
                                  <SwiperCarousel
                                    className="w-full h-full relative block"
                                    options={{
                                      autoplay: {
                                        delay: 2200
                                      }
                                    }}
                                    slides={component.carouselItem.map(
                                      (item, itemIndex) => {
                                        const itemkey = `${key}-carousel-${itemIndex}`;

                                        return (
                                          <li
                                            key={itemkey}
                                            className="w-full h-full relative block "
                                          >
                                            {(item?.carouselImage
                                              ?.childImageSharp?.fluid && (
                                              <Img
                                                className="w-full h-full relative block object-cover gpu z-10"
                                                fluid={
                                                  item.carouselImage
                                                    .childImageSharp.fluid
                                                }
                                                alt="Carousel Item"
                                              />
                                            )) || (
                                              <>
                                                {item &&
                                                  item.carouselVideoUrl && (
                                                    <Video
                                                      className="w-full h-full relative block object-cover"
                                                      src={
                                                        item.carouselVideoUrl
                                                      }
                                                    />
                                                  )}
                                              </>
                                            )}
                                          </li>
                                        );
                                      }
                                    )}
                                  />
                                </div>
                              </div>
                            </section>
                          );
                        }

                        break;

                      case `simpleImage`:
                        componentJSX = (
                          <section
                            key={key}
                            className="animation-appear animation-delay-5 relative mt-8 mb-8"
                          >
                            <div className="grid">
                              <figure className="grid-end-21 grid-start-4 sm:grid-end-24 sm:grid-start-1 relative">
                                {component?.simpleImageSource?.childImageSharp
                                  ?.fluid && (
                                  <Img
                                    className="w-full relative block"
                                    fluid={
                                      component.simpleImageSource
                                        .childImageSharp.fluid
                                    }
                                    alt="Gallery Item"
                                  />
                                )}
                              </figure>
                            </div>
                          </section>
                        );

                        break;

                      case `largeText`:
                        componentJSX = (
                          <section
                            key={key}
                            className="animation-appear animation-delay-5 relative mt-24 sm:mt-12 mb-48 sm:mb-12"
                          >
                            <article className="grid">
                              {component.largeTextHeading && (
                                <h3 className="grid-end-10 grid-start-5 sm:grid-end-22 sm:grid-start-2 mb-4 b1">
                                  — {component.largeTextHeading}
                                </h3>
                              )}

                              {component.largeTextContent && (
                                <h2
                                  className="grid-end-16 grid-start-4 sm:grid-end-22 sm:grid-start-2 f1 whitespace-pre-wrap"
                                  dangerouslySetInnerHTML={{
                                    __html: component.largeTextContent
                                  }}
                                ></h2>
                              )}

                              {component.largeTextSubheading && (
                                <h3 className="grid-end-4 grid-start-21 sm:grid-end-22 sm:grid-start-2 flex items-end sm:mt-12 b2">
                                  {component.largeTextSubheading}
                                </h3>
                              )}
                            </article>
                          </section>
                        );

                        break;

                      case `sideBySideText`:
                        componentJSX = (
                          <section
                            key={key}
                            className="animation-appear animation-delay-5 relative mt-16 sm:mt-0 mb-24 sm:mb-12"
                          >
                            <article className="grid">
                              {component.sideTextLeft && (
                                <h3 className="grid-end-6 grid-start-5 sm:grid-end-22 sm:grid-start-2 f4">
                                  {component.sideTextLeft}
                                </h3>
                              )}

                              {component.sideTextRight && (
                                <div
                                  className="animation-appear-right animation-delay-4 grid-end-8 grid-start-17 sm:grid-end-22 sm:grid-start-2 relative block mt-12 pb-12"
                                  style={{
                                    height: `${
                                      this.state.expandedItems?.includes(index)
                                        ? `auto`
                                        : `240px`
                                    }`
                                  }}
                                >
                                  <div className="w-full h-full relative overflow-hidden">
                                    <p
                                      className="grid-end-8 grid-start-17 sm:grid-end-22 sm:grid-start-2 sm:mt-12 b2 whitespace-pre-wrap"
                                      dangerouslySetInnerHTML={{
                                        __html: component.sideTextRight
                                      }}
                                    ></p>
                                  </div>

                                  <div
                                    style={{
                                      background: `linear-gradient(to bottom, rgba(255,255,255,0) 0, rgba(255,255,255,1) 25%)`,
                                      transform: `translate3d(0, ${
                                        this.state.expandedItems?.includes(
                                          index
                                        )
                                          ? `0`
                                          : `-2rem`
                                      }, 0)`
                                    }}
                                    className="transition-transform w-full h-12 absolute right-0 bottom-0 left-0 z-10 flex items-end"
                                  >
                                    <button
                                      type="button"
                                      className="w-full h-full relative block cursor-pointer text-left"
                                      onClick={() => {
                                        if (
                                          !this.state.expandedItems?.includes(
                                            index
                                          )
                                        ) {
                                          this.setState(prevState => ({
                                            expandedItems: [
                                              ...prevState.expandedItems,
                                              index
                                            ]
                                          }));
                                        } else {
                                          const expandedItemsClone = JSON.parse(
                                            JSON.stringify(
                                              this.state.expandedItems
                                            )
                                          );

                                          const currentIndex = expandedItemsClone?.indexOf(
                                            index
                                          );

                                          expandedItemsClone.splice(
                                            currentIndex,
                                            1
                                          );

                                          this.setState({
                                            expandedItems: expandedItemsClone
                                          });
                                        }
                                      }}
                                    >
                                      <p className="b2 underline">
                                        Read{` `}
                                        {this.state.expandedItems?.includes(
                                          index
                                        )
                                          ? `Less -`
                                          : `More +`}
                                      </p>
                                    </button>
                                  </div>
                                </div>
                              )}
                            </article>
                          </section>
                        );

                        break;

                      case `video`:
                        componentJSX = (
                          <section
                            key={key}
                            className="animation-appear animation-delay-5 relative mt-8 mb-8"
                          >
                            <div className="grid">
                              <div className="animation-appear-right animation-delay-5 grid-end-21 grid-start-4 sm:grid-end-24 sm:grid-start-1 relative">
                                <Video
                                  className="w-full relative block object-cover"
                                  src={component.videoUrl}
                                />
                              </div>
                            </div>
                          </section>
                        );

                        break;

                      default:
                        componentJSX = <></>;
                        break;
                    }

                    return componentJSX;
                  })}
              </div>
            )) || (
              <div className="project-page__content project-page__content--touch relative">
                {frontmatter?.mobileComponents?.[0] &&
                  frontmatter.mobileComponents.map((component, index) => {
                    const key = `section-${index}`;

                    let componentJSX;

                    switch (component.type) {
                      case `mixedCarousel`:
                        if (!component?.carouselItem?.[0]) {
                          componentJSX = <></>;
                        } else {
                          componentJSX = (
                            <section
                              key={key}
                              className="animation-appear animation-delay-5 relative mt-4 mb-4"
                            >
                              <div className="animation-appear animation-delay-5 grid">
                                <div className="grid-end-21 grid-start-4 sm:grid-end-24 sm:grid-start-1 relative">
                                  <SwiperCarousel
                                    className="w-full h-full relative block"
                                    options={{
                                      autoplay: {
                                        delay: 2200
                                      }
                                    }}
                                    slides={component.carouselItem.map(
                                      (item, itemIndex) => {
                                        const itemkey = `${key}-carousel-${itemIndex}`;

                                        return (
                                          <li
                                            key={itemkey}
                                            className="w-full h-full relative block"
                                          >
                                            {(item?.carouselImage
                                              ?.childImageSharp?.fluid && (
                                              <Img
                                                className="w-full h-full relative block object-cover"
                                                fluid={
                                                  item.carouselImage
                                                    .childImageSharp.fluid
                                                }
                                                alt="Carousel Item"
                                              />
                                            )) || (
                                              <>
                                                {item &&
                                                  item.carouselVideoUrl && (
                                                    <Video
                                                      className="w-full h-full relative block object-cover"
                                                      src={
                                                        item.carouselVideoUrl
                                                      }
                                                    />
                                                  )}
                                              </>
                                            )}
                                          </li>
                                        );
                                      }
                                    )}
                                  />
                                </div>
                              </div>
                            </section>
                          );
                        }

                        break;

                      case `simpleImage`:
                        componentJSX = (
                          <section
                            key={key}
                            className="animation-appear animation-delay-5 relative mt-4 mb-4"
                          >
                            <div className="grid">
                              <figure className="grid-end-21 grid-start-4 sm:grid-end-24 sm:grid-start-1 relative">
                                {component?.simpleImageSource?.childImageSharp
                                  ?.fluid && (
                                  <Img
                                    className="w-full relative block"
                                    fluid={
                                      component.simpleImageSource
                                        .childImageSharp.fluid
                                    }
                                    alt="Gallery Item"
                                  />
                                )}
                              </figure>
                            </div>
                          </section>
                        );

                        break;

                      case `simpleText`:
                        componentJSX = (
                          <section
                            key={key}
                            className={`animation-appear-right animation-delay-2 relative pt-12 pb-12 text-${component.simpleTextFontColor}`}
                            style={{
                              backgroundColor:
                                component.simpleTextBackgroundColor
                            }}
                          >
                            <article className="grid">
                              {component.simpleTextHeading && (
                                <h3 className="grid-end-8 grid-start-4 md:grid-end-10 sm:grid-end-22 sm:grid-start-2 f1">
                                  {component.simpleTextHeading}
                                </h3>
                              )}

                              {component.simpleTextContent && (
                                <div className="grid-end-8 grid-start-4 md:grid-end-10 sm:grid-end-22 sm:grid-start-2 mt-12 f4">
                                  {this.splitStringToParagraphs(
                                    component.simpleTextContent
                                  )}
                                </div>
                              )}
                            </article>
                          </section>
                        );

                        break;

                      case `largeText`:
                        componentJSX = (
                          <section
                            key={key}
                            className="animation-appear animation-delay-5 relative mt-24 sm:mt-12 mb-48 sm:mb-12"
                          >
                            <article className="grid">
                              {component.largeTextHeading && (
                                <h3 className="grid-end-10 grid-start-5 sm:grid-end-22 sm:grid-start-2 mb-4 b1">
                                  — {component.largeTextHeading}
                                </h3>
                              )}

                              {component.largeTextContent && (
                                <h2
                                  className="grid-end-16 grid-start-4 sm:grid-end-22 sm:grid-start-2 f1 whitespace-pre-wrap"
                                  dangerouslySetInnerHTML={{
                                    __html: component.largeTextContent
                                  }}
                                ></h2>
                              )}

                              {component.largeTextSubheading && (
                                <h3 className="grid-end-4 grid-start-21 sm:grid-end-22 sm:grid-start-2 flex items-end sm:mt-12 b2">
                                  {component.largeTextSubheading}
                                </h3>
                              )}
                            </article>
                          </section>
                        );

                        break;

                      case `video`:
                        componentJSX = (
                          <section
                            key={key}
                            className="animation-appear animation-delay-5 relative mt-4 mb-4"
                          >
                            <div className="grid">
                              <Video
                                className="animation-appear-right animation-delay-5 grid-end-21 grid-start-4 sm:grid-end-24 sm:grid-start-1 relative"
                                src={component.videoUrl}
                              />
                            </div>
                          </section>
                        );

                        break;

                      default:
                        componentJSX = <></>;
                        break;
                    }

                    return componentJSX;
                  })}
              </div>
            )}

            <section className="w-full pt-16 xs:pt-8 pb-24 xs:pb-12 relative">
              <div className="grid">
                <div className="grid-end-12 grid-start-13 sm:grid-end-22 sm:grid-start-2 relative">
                  {frontmatter?.credits && (
                    <article
                      className={`project-page__expander pt-3 ${
                        creditsExpanded ? `pb-8` : `pb-3`
                      } relative`}
                    >
                      <button
                        type="button"
                        className="sm:w-full flex sm:justify-between f2"
                        onClick={() =>
                          this.setState(prevState => ({
                            creditsExpanded: !prevState.creditsExpanded
                          }))
                        }
                      >
                        <h2>Project credits</h2>
                        {creditsExpanded ? (
                          <span className="ml-2"> -</span>
                        ) : (
                          <span className="ml-2"> +</span>
                        )}
                      </button>

                      {creditsExpanded && (
                        <div
                          className="animation-appear-down whitespace-pre-wrap mt-4 b1"
                          dangerouslySetInnerHTML={{
                            __html: frontmatter.credits
                          }}
                        ></div>
                      )}
                    </article>
                  )}

                  {relatedCampaigns && relatedCampaigns.length > 0 && (
                    <article
                      className={`project-page__expander pt-3 ${
                        campaignsExpanded ? `pb-8` : `pb-3`
                      } relative`}
                    >
                      <button
                        type="button"
                        className="sm:w-full flex sm:justify-between f2"
                        onClick={() =>
                          this.setState(prevState => ({
                            campaignsExpanded: !prevState.campaignsExpanded
                          }))
                        }
                      >
                        <h2>Other campaigns</h2>
                        {campaignsExpanded ? (
                          <span className="ml-2"> -</span>
                        ) : (
                          <span className="ml-2"> +</span>
                        )}
                      </button>

                      {campaignsExpanded &&
                        relatedCampaigns.map(relatedCampaign => {
                          let localeLink = relatedCampaign.fields.slug;

                          if (localeLink.includes(`.`)) {
                            [localeLink] = relatedCampaign.fields.slug.split(
                              `.`
                            );
                          }

                          return (
                            <LocaleLink
                              to={localeLink}
                              className="animation-appear-down block mt-4 b1"
                            >
                              {relatedCampaign.frontmatter.title}
                            </LocaleLink>
                          );
                        })}
                    </article>
                  )}

                  {nextProjectLink && (
                    <div className="py-3 relative">
                      <LocaleLink
                        to={nextProjectLink}
                        className="project-page__link relative flex"
                      >
                        <h2 className="project-page__link__text f2">
                          Next project
                        </h2>
                        <h3 className="project-page__link__arrow transition-opacity-transform relative block ml-2 f2">
                          →
                        </h3>
                      </LocaleLink>
                    </div>
                  )}
                </div>
              </div>
            </section>
          </div>

          <Newsletter />

          <GetInTouch />

          <Footer />
        </Layout>
      </>
    );
  }
}

//

const ProjectPage = ({ data, location }) => {
  const appContext = useContext(AppContext);
  const documentContext = useContext(DocumentContext);
  const { frontmatter } = data.markdownRemark;
  const otherProjects = [];

  data.allMarkdownRemark.edges.forEach(({ node }) => {
    if (
      node.fields.slug === data.markdownRemark.fields.slug ||
      !node?.frontmatter?.isPublished ||
      node.frontmatter.language !== frontmatter.language
    ) {
      return;
    }

    const { language } = node.frontmatter;

    if (appContext.locale.locale === language) {
      otherProjects.push(node);
    }
  });

  return (
    <ProjectPageComponent
      appContext={appContext}
      documentContext={documentContext}
      frontmatter={frontmatter}
      location={location}
      otherProjects={otherProjects}
    />
  );
};

export default ProjectPage;

export const query = graphql`
  query ProjectPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      fields {
        slug
      }

      frontmatter {
        title
        tagline
        language
        priority
        relatedProjects
        tags

        backgroundImage {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        backgroundImageAlt
        backgroundImageFontColor

        #campaigns
        credits

        components {
          type

          simpleImageSource {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }

          carouselItem {
            carouselImage {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }

            carouselVideoUrl
          }

          largeTextContent
          largeTextHeading
          largeTextSubheading
          sideTextLeft
          sideTextRight
          videoUrl
        }

        mobileComponents {
          type

          simpleImageSource {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }

          carouselItem {
            carouselImage {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }

            carouselVideoUrl
          }

          largeTextContent
          largeTextHeading
          largeTextSubheading

          simpleTextBackgroundColor
          simpleTextFontColor
          simpleTextContent
          simpleTextHeading

          sideTextLeft
          sideTextRight

          videoUrl
        }

        seoDescription
        seoKeywords
      }
    }

    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "project-page" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }

          frontmatter {
            title
            isPublished
            showOnWork
            language
            priority
          }
        }
      }
    }
  }
`;
